import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { instance } from "../../../api/axios";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { openErrorNotification, openNotification, openSuccessNotification } from "../../../utils/Notification";
import Questions from "../../Lessons/Question";
import { Modal, notification } from "antd";
import { CertificationResult, percentToRate } from "./ProfileCertification";
import { CERTIFICATION_PATH } from "../../../utils/const";
import { useAppDispatch } from "../../../store/hooks";
import { loadingStatus } from "../../../store/slices/appSlice";
import { attestationAPI } from "../../../api/api";

type Question = {
  id: number;
  multiple_choices: boolean;
  name: string;
  question_type: string;
  content: string | null;
  choices: {
    id: number;
    text: string;
    content: string | null;
    choice_type: string;
  };
};

const ProfileCertificationTest = () => {
  const { discipline_id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [testAttempt, setTestAttempt] = useState(1);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const fetch = async () => {
    try {
      dispatch(loadingStatus(true));
      if (discipline_id) {
        const { data } = await attestationAPI.getTest(discipline_id);
        initData(data.questions);
      }
    } catch (e) {
      openErrorNotification("Сейчас тест недоступен по техническим причинам, обратитесь к администратору");
      navigate(CERTIFICATION_PATH);
    } finally {
      dispatch(loadingStatus(false));
    }
  };
  useEffect(() => {
    fetch();
  }, [discipline_id, testAttempt]);

  const [initDataForm, setInitDataForm] = useState<null | any>(null);
  const [errorsForm, setErrorsForm] = useState<null | any>(null);

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { questionArray: initDataForm },
  });

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: "questionArray",
    shouldUnregister: true,
  });

  const initData = (test: Question[]) => {
    try {
      const forSave = test.map((item: any) => {
        if (item.choices) {
          item.choices = item.choices.map((inItem: any) => ({
            ...inItem,
            idChoice: inItem.id,
          }));
        }
        item.idDb = item.id;
        return item;
      });

      setInitDataForm(forSave);
      reset({ questionArray: forSave });
      setAnswers(undefined);
      setResult(undefined);
      setErrorsForm(null);
    } catch (e) {
      openErrorNotification("Ошибка загрузки теста");
    }
  };

  const checkError = (arrayData: any) => {
    let isSuccessful = true;

    arrayData.forEach((item: any, index: number) => {
      if (item.question_type === "MultipleSelect") {
        let result = item.choices.find((choiecesItem: any) => choiecesItem.selected === true);
        if (!result) {
          isSuccessful = false;
        }
      }
      if (item.question_type === "PassInputOption" && typeof item.choices === "object") {
        isSuccessful = false;
      }
      if (item.question_type === "Select" && typeof item.choices === "object") {
        isSuccessful = false;
      }
    });
    return isSuccessful;
  };

  const dataForPend = (arrayData: any) => {
    return arrayData.map((item: any) => {
      let itemObject: any = {
        question_id: item.id,
        user_input: item.question_type === "Input" || item.question_type === "PassInput" ? item.user_input : null,
        choices: null,
      };

      if (item.question_type === "MultipleSelect") {
        itemObject.choices = item.choices
          .filter((subItem: any) => subItem.selected)
          .map((subItem: any) => subItem.idChoice);
      }
      if (item.question_type === "Select" || item.question_type === "PassInputOption") {
        itemObject.choices = [Number(item.choices)];
      }

      return itemObject;
    });
  };

  const [answers, setAnswers] = useState<any>();
  const [result, setResult] = useState<{
    rate: number;
    user_score: number;
    max_score: number;
    count_of_attestations: number;
  }>();

  const onSubmit = (data: any) => {
    if (checkError(data.questionArray)) {
      setAnswers(data.questionArray);
    } else {
      openErrorNotification("Необходимо заполнить все поля в тесте");
    }
  };

  return (
    <section className="test certification__test">
      <div className="certification__subject _h3">{searchParams.get("test_subject")}</div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="test__title certification__test__title _h4">Тест</div>
        <ul className="test__list">
          {fields?.map((item: any, index) => (
            <li
              key={item.id}
              className={`test__item ${
                errorsForm &&
                item.idDb in errorsForm &&
                errorsForm[item.idDb].user_score < errorsForm[item.idDb].max_score &&
                "_error"
              }`}
              data-id={item.idDb}
            >
              <Questions
                type={item.question_type}
                item={item}
                watch={watch}
                index={index}
                control={control}
                errors={errors}
                register={register}
              />
            </li>
          ))}
        </ul>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gridGap: "10px",
            width: "fit-content",
          }}
        >
          <button type="submit" className="test__btn btn btn_3">
            Завершить
          </button>
        </div>

        <Modal
          className="certification__attention-modal certification__attempt-modal"
          open={!!answers}
          onCancel={() => setAnswers(undefined)}
          title="Вы уверены, что хотите завершить?"
          width={580}
          footer={
            <>
              <div className="certification__modal-footer">
                <div
                  className="btn certification__modal-footer__yellow-btn"
                  onClick={() =>
                    instance
                      .post(`tests/attestation/complete?discipline_id=${discipline_id}`, dataForPend(answers))
                      .then((rc) => {
                        setErrorsForm(rc.data.details);
                        openSuccessNotification("Успешная отправка теста");
                        setAnswers(undefined);
                        instance.get(`tests/attestation/get_results`).then((r) => {
                          setResult({
                            rate: percentToRate(rc.data.percent),
                            user_score: Object.keys(rc.data.details)
                              .map((k) => rc.data.details[k].user_score)
                              .reduce((p, c) => p + c, 0),
                            max_score: Object.keys(rc.data.details)
                              .map((k) => rc.data.details[k].max_score)
                              .reduce((p, c) => (p += c), 0),
                            count_of_attestations:
                              (r.data as CertificationResult[]).find((r) => r.discipline.id == +(discipline_id ?? 0))
                                ?.count_of_attestations ?? 3,
                          });
                        });
                      })
                      .catch(() => {
                        openErrorNotification("Ошибка отправки теста");
                        setAnswers(undefined);
                      })
                  }
                >
                  Да!
                </div>
                <div className="btn certification__modal-footer__white-btn" onClick={() => setAnswers(undefined)}>
                  Нет
                </div>
              </div>
            </>
          }
        >
          Будет засчитана попытка
        </Modal>

        <Modal
          className="certification__attention-modal certification__attempt-modal"
          open={!!result}
          title={`Ваша оценка: ${result?.rate}. Правильных ответов ${result?.user_score} из ${result?.max_score}`}
          width={620}
          footer={
            <>
              <div className="certification__modal-footer">
                <div
                  className="btn certification__modal-footer__yellow-btn"
                  onClick={() => navigate(CERTIFICATION_PATH)}
                >
                  {result?.count_of_attestations !== 3 ? "Согласен c оценкой" : "Перейти в раздел аттестации"}
                </div>
                {result?.count_of_attestations !== 3 && (
                  <div
                    className="btn certification__modal-footer__white-btn"
                    onClick={() => setTestAttempt((a) => a + 1)}
                  >
                    Пересдать
                  </div>
                )}
              </div>
            </>
          }
        >
          {result?.count_of_attestations !== 3
            ? "Вы согласны с результатом? Или хотите пройти тест еще раз?"
            : "К сожалению, у вас закончились попытки сдать этот тест"}
        </Modal>
      </form>
    </section>
  );
};
export default ProfileCertificationTest;
