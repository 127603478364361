import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { openErrorNotification, openSuccessNotification } from "../../utils/Notification";
import { disciplinesApi, lessonsApi } from "../../api/api";
import Loading from "../Loading/Loading";
import { CERTIFICATION_PATH, LESSON_PATH } from "../../utils/const";
import { useAppDispatch } from "../../store/hooks";
import { loadingStatus } from "../../store/slices/appSlice";
import { setStudyBook } from "../../store/slices/studyBookSlice";

const LessonsList = () => {
  const params = useParams<{ id: string }>();
  const [arrayLessons, setArrayLessons] = useState<Array<{}> | null>(null);
  const [countLessons, setCountLessons] = useState<number | null>(null);
  const [nameSubject, setNameSubject] = useState<string | null>(null);
  const [countCompleteLessons, setCountCompleteLessons] = useState<number | null>(null);

  const dispatch = useAppDispatch();

  const getLessons = async (discipline_id: number) => {
    try {
      dispatch(loadingStatus(true));
      const { data } = await lessonsApi.getLessons(discipline_id);
      setArrayLessons(data);
      console.log(data);
      let count = 0;
      let countComplete = 0;
      data.forEach((item: any) => {
        item.lessons.forEach((lessons: any) => {
          count = count + 1;
          if (lessons.test_is_completed) {
            countComplete = countComplete + 1;
          }
        });
      });
      setCountLessons(count);
      setCountCompleteLessons(countComplete);
      const response = await disciplinesApi.getDiscipline(discipline_id);
      setNameSubject(response.data.name);
      dispatch(loadingStatus(false));
    } catch (e) {
      openErrorNotification("Ошибка получение уроков");
      console.error(e);
    }
  };

  useEffect(() => {
    if (params.id) {
      getLessons(Number(params.id));
      dispatch(setStudyBook(Number(params.id)));
    }
    return () => {
      dispatch(setStudyBook(null));
    };
  }, []);

  if (!arrayLessons?.length) {
    return <Loading />;
  }

  return (
    <section className="listLessons">
      <div className="listLessons__top">
        <h2 className="subjects__title listLessons__title _h3">{nameSubject ? nameSubject : "Подготовка"}</h2>
        <div className="listLessons__count">
          Пройдено {countCompleteLessons}/{countLessons}
        </div>
      </div>
      <div className="listLessons__info-block">
        <div className="listLessons__info-block__background"></div>

        <div className="listLessons__info-text">Выберите тему для проверки знаний или пройдите пробную аттестацию</div>
        <Link className="listLessons__info-btn btn " to={CERTIFICATION_PATH}>
          Пробная аттестация
        </Link>
      </div>

      <ul className="listLessons__list">
        {arrayLessons &&
          arrayLessons.map((item: any, count: number) => {
            if (item.id) {
              return (
                <li key={count} className="listLessons__item">
                  <div className="listLessons__item-title">{item.name}</div>
                  <ul className="listLessons__list-2">
                    {item.lessons.map((lessons: any) => (
                      <li
                        key={lessons.id}
                        className={`listLessons__item-2 ${lessons.test_is_completed ? "_complete" : ""}`}
                      >
                        <Link className="listLessons__link" to={`${LESSON_PATH}/${lessons.id}`}>
                          {lessons.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            }
          })}
      </ul>
    </section>
  );
};

export default LessonsList;
