import React from "react";
import { Link, NavLink } from "react-router-dom";
import IconStore from "../../media/iconStore";
import { showMobMenu } from "../../store/slices/appSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { HostMainSite, MAIN_PATH, PROFIlE_PATH, REGISTRATION_PATH, typeAppMobile } from "../../utils/const";
import { logout } from "../../store/slices/authSlice";

const Header: React.FC = () => {
  const dispatch = useAppDispatch();

  const { isAuth, firstName, secondName } = useAppSelector((state) => state.auth);
  const { showMobMenu: isShowMenu, showSubMobMenu, typeApp } = useAppSelector((state) => state.app);

  const onLogout = () => {
    dispatch(logout());
  };

  const toggleMobMenu = () => {
    dispatch(showMobMenu(!isShowMenu));
  };

  return (
    <header className={`header ${showSubMobMenu && "_menu-open"}`}>
      <div className="container">
        <div className="header__inner">
          <div className="header__logo-wrap _2">
            <Link className="header__logo logo" to={MAIN_PATH}>
              <img className="logo__img" src={IconStore.LogoImg} alt="logo" width="260" height="80" />
            </Link>
          </div>

          <div className="header__right-wrap _2">
            <div className={`header__nav-wrap ${isShowMenu ? "_active" : ""}`}>
              <nav className="header__nav menu">
                <button
                  className="header__burger header__burger_close  js-toggle-menu"
                  type="button"
                  onClick={toggleMobMenu}
                >
                  <img className="burger__img" src={IconStore.BurgerMenuClose} alt="burger" width="40" height="40" />
                  <span className="sr-only">Закрыть мобильное меню</span>
                </button>
                {!isAuth && (
                  <ul className="menu__list">
                    <li className="menu__item">
                      <div className="menu__item-top">
                        <div className="menu__link _arrow">Классы</div>

                        <div className="menu__sublist-wrap">
                          <ul className="menu__sublist">
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="1 класс"
                                to={`${HostMainSite}classes/one/`}
                                target={"_blank"}
                              >
                                1 класс
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="2 класс"
                                to={`${HostMainSite}classes/two/`}
                                target={"_blank"}
                              >
                                2 класс
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="3 класс"
                                to={`${HostMainSite}classes/three/`}
                                target={"_blank"}
                              >
                                3 класс
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="4 класс"
                                to={`${HostMainSite}classes/four/`}
                                target={"_blank"}
                              >
                                4 класс
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="5 класс"
                                to={`${HostMainSite}classes/five/`}
                                target={"_blank"}
                              >
                                5 класс
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="6 класс"
                                to={`${HostMainSite}classes/six/`}
                                target={"_blank"}
                              >
                                6 класс
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="7 класс"
                                to={`${HostMainSite}classes/seven/`}
                                target={"_blank"}
                              >
                                7 класс
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="8 класс"
                                to={`${HostMainSite}classes/eight/`}
                                target={"_blank"}
                              >
                                8 класс
                              </Link>
                            </li>

                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="9 класс"
                                to={`${HostMainSite}classes/nine/`}
                                target={"_blank"}
                              >
                                9 класс
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="10 класс"
                                to={`${HostMainSite}classes/ten/`}
                                target={"_blank"}
                              >
                                10 класс
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                data-text="11 класс"
                                to={`${HostMainSite}classes/eleven/`}
                                target={"_blank"}
                              >
                                11 класс
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>

                    <li className="menu__item">
                      <div className="menu__item-top">
                        <Link className="menu__link" to={`${HostMainSite}contacts`} target={"_blank"}>
                          Контакты
                        </Link>
                      </div>
                    </li>

                    <li className="menu__item">
                      <div className="menu__item-top">
                        <div className="menu__link _arrow">Родителям</div>

                        <div className="menu__sublist-wrap _second">
                          <ul className="menu__sublist">
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                to={`${HostMainSite}docs`}
                                target={"_blank"}
                                data-text=" Документы и лицензии"
                              >
                                Документы и лицензии
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                to={`${HostMainSite}answers`}
                                target={"_blank"}
                                data-text="Вопрос-ответ"
                              >
                                Вопрос-ответ
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                to={`${HostMainSite}blog`}
                                target={"_blank"}
                                data-text="Блог"
                              >
                                Блог
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                to={`${HostMainSite}news`}
                                target={"_blank"}
                                data-text="Новости"
                              >
                                Новости
                              </Link>
                            </li>
                            <li className="menu__subitem">
                              <Link
                                className="menu__sublink"
                                to={`${HostMainSite}information`}
                                target={"_blank"}
                                data-text="Информация о СО"
                              >
                                Информация о СО
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                )}

                <a className="menu__btn btn popup--link" href="#feedback">
                  Отправить заявку
                </a>
              </nav>
            </div>

            {isAuth || typeApp === typeAppMobile ? (
              <div className="header__phone _2">
                <Link reloadDocument className="header__phone-link text-bold" to="tel:89005555555">
                  8 900 555 55 55
                </Link>
                <span className="header__schedule">c 8:00 до 20:00</span>
              </div>
            ) : (
              ""
            )}

            <button className="header__burger" type="button" onClick={toggleMobMenu}>
              <img className="burger__img" src={IconStore.BurgerMenu} alt="burger" width="40" height="40" />
              <span className="sr-only">Открыть мобильное меню</span>
            </button>

            {isAuth ? (
              ""
            ) : (
              <a className="header__btn btn popup--link" href="#authPopUp">
                Отправить заявку
              </a>
            )}

            <div className={`header__auth-btn-block ${isAuth ? "_auth" : ""}`}>
              <Link className="header__auth-btn" to={isAuth ? MAIN_PATH : REGISTRATION_PATH}>
                {isAuth ? (
                  <img
                    className="header__auth-btn-icon"
                    src={IconStore.AvatarIcon}
                    alt="entry"
                    width="40"
                    height="40"
                  />
                ) : (
                  <img
                    className="header__auth-btn-icon _2"
                    src={IconStore.EntryIcon}
                    alt="entry"
                    width="40"
                    height="40"
                  />
                )}
              </Link>
              <div className="header__auth-list-wrap">
                {isAuth ? (
                  <ul className="header__auth-list">
                    <li className="header__auth-item _active">{`${firstName} ${secondName}`}</li>
                    <li className="header__auth-item">
                      <a className="header__auth-link" href="#">
                        Добавить ученика
                      </a>
                    </li>
                    <li className="header__auth-item">
                      <button className="header__auth-link" onClick={onLogout}>
                        Выйти
                      </button>
                    </li>
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
