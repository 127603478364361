import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadingStatus } from "./appSlice";
import { authAPI, profileAPI } from "../../api/api";
import { LoginMailPopUpType } from "../../type/type";

export interface MyDocTypeItem {
  id: number;
  type: string;
  url: string;
  approved: boolean;
  details: string;
  file_extension: string;
}

export interface MyDocType {
  "Документы родителя": Array<MyDocTypeItem>;
  "Документы ученика": Array<MyDocTypeItem>;
  Справки: Array<MyDocTypeItem>;
}

export interface InitialStateType {
  myDocs: MyDocType | null;
  myDisciplines: Array<any> | null;
}

const initialState: InitialStateType = {
  myDocs: null, //Раздел документы в лк Ученика
  myDisciplines: null, // Список дисциплин в оценкой в лк Ученика
};

export const profileReducer = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMyDoc: (state, action: PayloadAction<MyDocType>) => {
      state.myDocs = action.payload;
    },
    setMyDisciplines: (state, action: PayloadAction<Array<any>>) => {
      state.myDisciplines = action.payload;
    },
  },
});

export const { setMyDoc, setMyDisciplines } = profileReducer.actions;

export const getMyDocuments = () => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await profileAPI.getMyDoc();
    dispatch(setMyDoc(response.data));
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
    console.log(err);
  }

  dispatch(loadingStatus(false));
};

export const getMyDisciplines = () => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    const response = await profileAPI.getMyAssessments();
    dispatch(setMyDisciplines(response.data));
  } catch (err: any) {
    if (err.response) {
      console.log(err.response);
    }
    console.log(err);
  }

  dispatch(loadingStatus(false));
};

export default profileReducer.reducer;
