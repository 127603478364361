import React from "react";
import ReactDOM from "react-dom/client";
import MainApp from "./MainApp";
import { Provider } from "react-redux";
import { store } from "./store/store";
import { ConfigProvider } from "antd";
import "@ant-design/v5-patch-for-react-19";
import ruRu from "antd/locale/ru_RU";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ConfigProvider locale={ruRu}>
    <Provider store={store}>
      <MainApp />
    </Provider>
  </ConfigProvider>
);
