import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { typeAppDesktop } from "../../utils/const";
import { authAPI, bookAPI } from "../../api/api";
import { setAuth, setAuthData, setUserId } from "./authSlice";
import { loadingStatus } from "./appSlice";

export interface InitialStateType {
  bookList: Array<any> | null;
}

const initialState: InitialStateType = {
  bookList: null,
};

export const studyBookReducer = createSlice({
  name: "studyBook",
  initialState,
  reducers: {
    setBook: (state, action: PayloadAction<Array<any> | null>) => {
      state.bookList = action.payload;
    },
  },
});

export const { setBook } = studyBookReducer.actions;

export const setStudyBook = (id_disciplines: number | null) => async (dispatch: any) => {
  dispatch(loadingStatus(true));

  try {
    if (id_disciplines) {
      const response = await bookAPI.getMyBooksForDisciplines(id_disciplines);
      dispatch(setBook(response.data?.books));
    } else {
      dispatch(setBook(null));
    }
  } catch (err: any) {
    if (!err.response) {
      console.error("Нет ответа от сервера");
    } else if (err.response.status === 403) {
      console.warn(`Не авторизованный пользователь: ${err.response.status}`);
    } else console.error(err);
  }

  dispatch(loadingStatus(false));
};

export default studyBookReducer.reducer;
