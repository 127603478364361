import { Link } from "react-router-dom";
import { REGISTRATION_PATH_1, REGISTRATION_PATH_4 } from "../utils/const";

const NotCount = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <div className="notification__icon"></div>
      <div className="notification__content">
        <div className="notification__title _h4">Не оплачено обучение</div>
        <div className="notification__text">Пожалуйста, свяжитесь с нашим администратором</div>
        <div className="notification__btns">
          <Link className={"btn notification__btn"} to={REGISTRATION_PATH_4}>
            Оплатить
          </Link>
          <Link className={"btn notification__btn"} to={REGISTRATION_PATH_1}>
            Редактировать анкету
          </Link>
          <button className={"btn notification__btn"} onClick={onClose}>
            Войти в аккаунта без оплаты
          </button>
        </div>
      </div>
    </>
  );
};

export default NotCount;
