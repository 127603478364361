import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../Header/Header";
import Loading from "../Loading/Loading";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { initializeApp, showMobMenu } from "../../store/slices/appSlice";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import { Modal } from "antd";
import NotCount from "../../pages/NotCount";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [isQuest, setIsQest] = useState(false);

  const loading = useAppSelector((state) => state.app.loading);
  const isInit = useAppSelector((state) => state.app.init);
  const { firstName, secondName, role } = useAppSelector((state) => state.auth);

  useEffect(() => {
    dispatch(showMobMenu(false));
  }, [location, dispatch]);

  useEffect(() => {
    dispatch(initializeApp());
  }, []);

  useEffect(() => {
    document.title = `Сдавайкин - личный кабинет  ${firstName} ${secondName}`;
  }, [firstName]);

  useEffect(() => {
    if (role === "quest") {
      setIsQest(true);
    }
  }, [role]);

  const onClose = () => {
    setIsQest(false);
  };

  if (!isInit) {
    return <Loading />;
  }

  return (
    <>
      {loading ? <Loading /> : ""}

      <Header />

      <Breadcrumbs />

      <main className="main">
        <Outlet />
      </main>
      <Modal width={600} title="" className="modal-bg-blue" open={isQuest} footer={false} onCancel={onClose}>
        <NotCount onClose={onClose} />
      </Modal>
    </>
  );
};

export default App;
