import React, { useEffect, useState } from "react";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { setTypeApp } from "./store/slices/appSlice";
import { typeAppDesktop, typeAppMobile } from "./utils/const";

const routers = router;

function MainApp() {
  const dispatch = useAppDispatch();
  const { firstName, secondName, isAuth } = useAppSelector((state) => state.auth);
  const [width, setWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    if (width < 769) {
      dispatch(setTypeApp(typeAppMobile));
    }
  }, [dispatch]);

  window.onresize = () => {
    setWidth(window.innerWidth);
    if (width < 769) {
      dispatch(setTypeApp(typeAppMobile));
    } else {
      dispatch(setTypeApp(typeAppDesktop));
    }
  };

  useEffect(() => {
    if (isAuth && firstName && secondName) {
      document.title = `Сдавайкин - личный кабинет ${firstName} ${secondName}`;
    }
  }, []);

  return <RouterProvider router={routers} />;
}

export default MainApp;
