export const emailField = {
  required: "Поле обязательно для заполнение",
  pattern: {
    value:
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/,
    message: "Недопустимый формат",
  },
  maxLength: {
    value: 64,
    message: "Максимум 64 символа",
  },
};

export const loginEmailField = {
  required: "Поле обязательно для заполнение",
  pattern: {
    value: /^[a-zA-Z@.0-9]+$/g,
    message: "Только буквы и цифры",
  },
  minLength: {
    value: 1,
    message: "Минимальная длина 1 символов",
  },
  maxLength: {
    value: 64,
    message: "Максимум 64 символа",
  },
};

export const nameFieldNotReq = {
  pattern: {
    value: /^[a-zA-Zа-яА-ЯёЁ ]+$/g,
    message: "Только буквы",
  },
  minLength: {
    value: 1,
    message: "Минимальная длина 1 символов",
  },
  maxLength: {
    value: 64,
    message: "Максимум 64 символа",
  },
};

export const passField = {
  required: "Поле обязательно для заполнение",
  // pattern: {
  //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,}$/,
  //     message: "Пароль должен содержать заглавные и строчные латинские буквы, цифры, спец. символы. Длина от 8 символов"
  // }
  maxLength: {
    value: 128,
    message: "Максимум 128 символа",
  },
};

export const requiredField = {
  required: "Поле обязательно для заполнение",
};

export const nameField = {
  required: "Поле обязательно для заполнение",
  pattern: {
    value: /^[a-zA-Zа-яА-ЯёЁ ]+$/g,
    message: "Только буквы",
  },
  minLength: {
    value: 1,
    message: "Минимальная длина 1 символов",
  },
  maxLength: {
    value: 64,
    message: "Максимум 64 символа",
  },
};

export const phoneField = {
  required: "Поле обязательно для заполнение",
  validate: (data: string) => {
    if (data && data.replace(/\D/g, "").length !== 11) {
      return "Неверный формат";
    }
    return true;
  },
};

export const departamentCodeField = {
  required: "Поле обязательно для заполнение",
  maxLength: {
    value: 7,
    message: "Недопустимый формат",
  },
  minLength: {
    value: 7,
    message: "Недопустимый формат",
  },
};

export const pasportNumberField = {
  required: "Поле обязательно для заполнение",
  maxLength: {
    value: 6,
    message: "Недопустимый формат",
  },
  minLength: {
    value: 6,
    message: "Недопустимый формат",
  },
};
export const pasportSeriesField = {
  required: "Поле обязательно для заполнение",
  maxLength: {
    value: 5,
    message: "Недопустимый формат",
  },
  minLength: {
    value: 5,
    message: "Недопустимый формат",
  },
};
export const insuranceField = {
  required: "Поле обязательно для заполнение",
  validate: (data: string) => {
    if (data && data.replace(/\D/g, "").length !== 11) {
      return "Неверный формат";
    }
    return true;
  },
};
export const seriesCertificateField = {
  required: "Поле обязательно для заполнение",
  pattern: {
    value: /^[A-Za-z]{1,3}-[A-Za-z]{1,3}$/,
    message: 'Недопустимый формат, вводите в такой форме "R-AA"',
  },
};
export const numberCertificateField = {
  required: "Поле обязательно для заполнение",
  pattern: {
    value: /^\d{6}$/,
    message: 'Недопустимый формат, вводите в такой форме "999999"',
  },
};
export const seriesNumberCertificateField = {
  required: "Поле обязательно для заполнение",
  pattern: {
    value: /^.{1,3}-.{1,3}[ -]\d{6}$/,
    message: 'Недопустимый формат, вводите в такой форме "R-ББ 999999"',
  },
};

export const textSmallField = {
  required: "Поле обязательно для заполнение",
  pattern: {
    value: /^[a-zA-Zа-яА-ЯёЁ.,:/ \d-]+$/g,
    message: "Недопустимые символы",
  },
  minLength: {
    value: 5,
    message: "Минимальная длина 5 символов",
  },
  maxLength: {
    value: 200,
    message: "Максимум 200 символа",
  },
};

export const loginField = {
  required: "Поле обязательно для заполнение",
  pattern: {
    value: /^[a-zA-Z]+$/g,
    message: "Только буквы",
  },
  minLength: {
    value: 1,
    message: "Минимальная длина 1 символов",
  },
  maxLength: {
    value: 64,
    message: "Максимум 64 символа",
  },
};
