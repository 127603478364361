import React from "react";
import IconStore from "../../media/iconStore";
import { Link, NavLink } from "react-router-dom";
import { CERTIFICATION_PATH, DOCUMENTS_PATH, MAIN_PATH, PREPARATION_PATH, TEXTBOOKS_PATH } from "../../utils/const";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { logout } from "../../store/slices/authSlice";

const ProfileMenu = ({ isShowMenu, setShowMenu }: { isShowMenu: boolean; setShowMenu: () => void }) => {
  const books = useAppSelector((state) => state.studyBook.bookList);
  const { role } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const onLogout = () => {
    dispatch(logout());
  };

  return (
    <div className={`cabinet__menu ${isShowMenu ? "_open" : ""}`}>
      <button className="cabinet__menu-close-btn" type="button" onClick={setShowMenu}>
        <svg
          className="cabinet__menu-close"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_725_30629)">
            <path
              className="cabinet__menu-close-2"
              d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95428 31.0457 0 20 0C8.95433 0 0 8.95428 0 20C0 31.0457 8.95433 40 20 40Z"
              fill="#D0ECB3"
            />
            <path
              className="cabinet__menu-close-1"
              d="M24.9497 15.0503L15.0502 24.9498"
              stroke="#67676A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              className="cabinet__menu-close-1"
              d="M15.0503 15.0503L24.9498 24.9498"
              stroke="#67676A"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_725_30629">
              <rect width="40" height="40" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>

      <nav className="cabinet__nav">
        <ul className="cabinet__menu-list">
          <li className="cabinet__menu-item">
            <NavLink className={({ isActive }) => `cabinet__menu-link ${isActive ? "_active" : ""}`} to={MAIN_PATH}>
              <img className="cabinet__menu-img" src={IconStore.Men} alt="cabinet__menu" width="20" height="20" />
              <span>Личный кабинет</span>
            </NavLink>
          </li>
          {role !== "quest" && (
            <>
              <li className="cabinet__menu-item">
                <NavLink
                  className={({ isActive }) => `cabinet__menu-link ${isActive ? "_active" : ""}`}
                  to={PREPARATION_PATH}
                >
                  <img className="cabinet__menu-img" src={IconStore.List} alt="cabinet__menu" width="20" height="20" />
                  <span>Подготовка</span>
                </NavLink>
              </li>
              <li className="cabinet__menu-item">
                <NavLink
                  className={({ isActive }) => `cabinet__menu-link ${isActive ? "_active" : ""}`}
                  to={TEXTBOOKS_PATH}
                >
                  <img
                    className="cabinet__menu-img"
                    src={IconStore.Textbook}
                    alt="cabinet__menu"
                    width="20"
                    height="20"
                  />
                  <span>Учебники</span>
                </NavLink>
              </li>
              <li className="cabinet__menu-item">
                <NavLink
                  className={({ isActive }) => `cabinet__menu-link ${isActive ? "_active" : ""}`}
                  to={CERTIFICATION_PATH}
                >
                  <img
                    className="cabinet__menu-img"
                    src={IconStore.School}
                    alt="cabinet__menu"
                    width="20"
                    height="20"
                  />
                  <span>Аттестация</span>
                </NavLink>
              </li>
              <li className="cabinet__menu-item">
                <NavLink
                  className={({ isActive }) => `cabinet__menu-link ${isActive ? "_active" : ""}`}
                  to={DOCUMENTS_PATH}
                >
                  <img className="cabinet__menu-img" src={IconStore.Doc1} alt="cabinet__menu" width="20" height="20" />
                  <span>Документы</span>
                </NavLink>
              </li>
            </>
          )}

          <li className="cabinet__menu-item">
            <button className="cabinet__menu-link _exit" onClick={onLogout}>
              <img className="cabinet__menu-img" src={IconStore.Exit} alt="cabinet__menu" width="20" height="20" />
              <span>Выйти</span>
            </button>
          </li>
        </ul>
      </nav>

      {books && books?.length > 0 && (
        <div className="aside-book">
          <div className="aside-book__title _h4">Учебник по предмету:</div>
          <ul className="aside-book__list">
            {books?.map((i: any) => (
              <li key={i.id} className="aside-book__item">
                <Link className="aside-book__link" to={`${TEXTBOOKS_PATH}/${i.id}`}>
                  <div className="aside-book__img-wrap">
                    <img
                      className="aside-book__img"
                      src={`${process.env.REACT_APP_BACK_URL}contents/${i.thumbnail.url.slice(9)}`}
                      alt=""
                    />
                  </div>
                  <div className="aside-book__name">{i.name}</div>
                  <div className="aside-book__author">{i.author}</div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ProfileMenu;
