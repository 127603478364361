import React, { useEffect } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import { REGISTRATION_PATH, REGISTRATION_PATH_4 } from "../../utils/const";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setStartData, setStatusRegistrationStart } from "../../store/slices/registrationSlice";
import { logout } from "../../store/slices/authSlice";

const RegistrationAside: React.FC = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const nameStudentStore = useAppSelector((state) => state.registration.name);
  const classNumberStore = useAppSelector((state) => state.registration.classNumber);
  const yearPrice = useAppSelector((state) => state.registration.yearPrice);

  const nameStudent = localStorage.getItem("currentRegistrationName");
  const surnameStudent = localStorage.getItem("currentRegistrationSurname");
  const classNumber = localStorage.getItem("currentRegistrationClass");

  useEffect(() => {
    if (nameStudent && !nameStudentStore) {
      if (surnameStudent) {
        dispatch(
          setStartData({
            name: nameStudent,
            surname: surnameStudent,
            classNumber: Number(classNumber),
          })
        );
      }
    }
  }, [nameStudentStore, classNumberStore]);

  if (!nameStudent && !classNumber) {
    return <Navigate to={REGISTRATION_PATH} />;
  }

  const onAsideOther = () => {
    dispatch(logout());
    dispatch(setStatusRegistrationStart(false));
  };

  if (location.pathname.includes(REGISTRATION_PATH_4)) {
    return (
      <div className="cabinet__aside">
        <div className="registration__aside">
          <div className="registration__aside-blue">
            <div className="registration__aside-blue__title _h4">Новый ученик,</div>
            Привет! Я помогу тебе сдать обязательную школьную аттестацию дистанционно. Даже если ты не посещаешь обычную
            школу по определенным причинам, мы поможем тебе с получением аттестата об образовании
            <div className="registration__cat-sitting"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="cabinet__aside">
      <div className="registration__aside">
        <div className="registration__aside-item">
          <div className="registration__aside-name _h4">{`${surnameStudent} ${nameStudent}`}</div>
          <ul className="registration__aside-list">
            <li className="registration__aside-list-item">
              <span>Поступает в </span>
              <b>{classNumber} класс</b>
            </li>
            {yearPrice ? (
              <li className="registration__aside-list-item">
                <span>Стоимость: </span>
                <b>{yearPrice} ₽/мес </b>
              </li>
            ) : (
              ""
            )}
          </ul>
        </div>
        <Link to={REGISTRATION_PATH}>
          <button className="registration__aside-other" onClick={onAsideOther}>
            Зачислить другого ребенка
          </button>
        </Link>
      </div>
    </div>
  );
};

export default RegistrationAside;
