import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { NOT_COUNT_PATH, REGISTRATION_PATH } from "../../utils/const";
import IconStore from "../../media/iconStore";
import ProfileMenu from "./ProfileMenu";
import { initializeApp, setShowSubMobMenu } from "../../store/slices/appSlice";

const Profile: React.FC = () => {
  let location = useLocation();
  const dispatch = useAppDispatch();
  const isShowSubMenu = useAppSelector((state) => state.app.showSubMobMenu);

  const { isAuth, firstName, secondName, className, role } = useAppSelector((state) => state.auth);

  const onSetShowMenu = () => {
    dispatch(setShowSubMobMenu(!isShowSubMenu));
  };

  useEffect(() => {
    dispatch(setShowSubMobMenu(false));
  }, [location]);

  useEffect(() => {
    if (!firstName) {
      dispatch(initializeApp());
    }
  }, [firstName]);

  if (!isAuth) {
    return <Navigate to={REGISTRATION_PATH} />;
  }

  return (
    <section className="cabinet ">
      {location.pathname.indexOf("profile/documents") !== -1 ? (
        <img className="cabinet__decor _1" src={IconStore.DecorReg4} alt="reg1.svg" width="464" height="650" />
      ) : (
        <img className="cabinet__decor _1" src={IconStore.DecorReg1} alt="reg1.svg" width="464" height="650" />
      )}

      <div className="container">
        <div className="cabinet__grid">
          <div className="cabinet__aside">
            <div className="cabinet__aside-top">
              <div className="cabinet__aside-top-left">
                <div className="cabinet__aside-top-name _h4">{`${firstName} ${secondName}`}</div>
                <div className="cabinet__aside-top-class">{className}</div>
              </div>
              <button className="cabinet__aside-top-btn btn btn_2" type="button" onClick={onSetShowMenu}>
                Открыть меню
              </button>
            </div>

            <ProfileMenu isShowMenu={isShowSubMenu} setShowMenu={onSetShowMenu} />
          </div>

          <div className="cabinet__content">
            <Outlet />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Profile;
